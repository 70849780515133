















































































































































































































































































































































import Avatar from '@/modules/community/common/components/avatar.vue'
import { Observer } from 'mobx-vue'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

import { createPostController } from './create-post-controller'
import { when } from 'mobx'
import { debounce } from 'lodash'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

@Observer
@Component({
  components: {
    Avatar,
    'og-card': () => import('@/modules/common/components/posts/og-card.vue'),
    'create-post-editor': () => import('@/modules/common/components/posts/create-post-editor.vue'),
    'poll-list-draggable': () => import('@/modules/common/components/posts/poll-list-draggable.vue'),
    'select-audience': () => import('@/modules/common/components/posts/select-audience.vue'),
    'v-custom-select': () => import('@/modules/common/components/form/v-custom-select.vue'),
    'repost-card-in-dialog': () => import('@/modules/common/components/posts/repost-card-in-dialog.vue'),
    'handle-post-button': () => import('@/modules/common/dialogs/create-post/handle-post-button.vue'),
    VueHcaptcha,
    'review-guideline-dialog': () => import('@/modules/common/dialogs/create-post/review-guideline-dialog.vue'),
    'tag-location-dialog': () => import('@/modules/common/dialogs/create-post/tag-location-dialog.vue'),
    'confirm-discard-post-dialog': () => import('@/modules/common/dialogs/post/confirm-discard-post-dialog.vue'),
  },
})
export default class extends Vue {
  @Ref('vueHcaptcha') vueHcaptcha: any
  @Ref('create-post-editor') createPostEditor: any
  @Ref('select-audience') selectAudience: any
  @Ref('audience-detail') audienceDetail: any
  @Ref('review-categories') reviewCategories: any
  createPostController = createPostController
  createLoading = false
  walletStore = walletStore
  sitekey = ''

  requireCaptcha = true
  timeout

  mounted() {
    this.sitekey = process.env.VUE_APP_HCAPTCHA_SITE_KEY || ''
    this.addScrollListener()
    setTimeout(() => {
      this.requireCaptcha = false
    }, 30000)
  }

  // fix tag is auto close when focus on mobile
  // tagInputOnFocus() {
  //   this.isDelayBlur = true
  //   setTimeout(() => {
  //     this.isDelayBlur = false
  //   }, 1000)
  // }

  addMediaBlock(id, blockType: 'image' | 'video' | 'link') {
    this.createPostEditor?.addMediaBlock(id, blockType)
  }

  toggleEmoji() {
    this.createPostEditor?.toggleEmoji()
  }

  async addScrollListener() {
    await when(() => createPostController.createPostDialog)
    const scrollWrapper = document.getElementsByClassName('post-content-wrapper')[0]
    scrollWrapper?.addEventListener(
      'scroll',
      debounce(() => {
        this.selectAudience?.blur()
        // if (!this.isDelayBlur) {
        //   this.autocompleteTag?.blur()
        // }
        this.audienceDetail?.blur()
        this.reviewCategories?.blur()
      }, 100)
    )
  }

  hcaptchaOnOpen() {
    this.createPostController.resetHCaptchaToken()
  }

  async hcaptchaOnVerify(token) {
    this.createLoading = true
    this.createPostEditor.updateTextBlocks()
    this.createPostController.createPostWithCaptcha(token)
    this.createLoading = false
    if (!createPostController.isValidInput) return

    //captcha is opened right away posting successfully, it'll be closed after 30s
    clearTimeout(this.timeout)
    this.requireCaptcha = true
    setTimeout(() => {
      this.requireCaptcha = false
    }, 30000)
  }

  async requestChangllenge() {
    this.createPostController.createPostLoading = true
    this.vueHcaptcha.hcaptcha.execute()
  }

  async createPost() {
    //Execute HCaptcha
    this.createLoading = true
    this.createPostEditor.updateTextBlocks()
    await createPostController.createPost()
    this.createLoading = false
    if (!createPostController.isValidInput) return

    //captcha is opened right away posting successfully, it'll be closed after 30s
    this.requireCaptcha = true
    this.timeout = setTimeout(() => {
      this.requireCaptcha = false
    }, 30000)
  }

  async createPostAction() {
    // if (!this.requireCaptcha) {
    //   await this.createPost()
    // } else {
    //   createPostController.verifyPostInputs()
    //   if (!createPostController.isValidInput) return
    //   await this.requestChangllenge()
    // }
    await this.createPost()
  }

  closeDialog() {
    if (createPostController.createPostLoading) return
    this.createPostController.changeOpenConfirmDiscardPostDialog(true)
  }
}
